import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ShowDialog = class ShowDialog extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = false;
    }
    async show() {
        this.dialog = true;
    }
    async close() {
        this.dialog = false;
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], ShowDialog.prototype, "propPersistent", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ShowDialog.prototype, "propCancel", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ShowDialog.prototype, "propSmall", void 0);
__decorate([
    Prop({ type: String, default: '40%' })
], ShowDialog.prototype, "propWidth", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ShowDialog.prototype, "propTitle", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ShowDialog.prototype, "propChip", void 0);
__decorate([
    Prop({ type: String, default: '' })
], ShowDialog.prototype, "propClass", void 0);
ShowDialog = __decorate([
    Component
], ShowDialog);
export default ShowDialog;
